import React from 'react'
import PageHeader from '../../components/common/PageHeader'

const SpareParts = () => {
  return (
    <section className='welcome-section'>
      <PageHeader title={"Spare Parts"} subNav={"Spare Parts"} />
      <div className='bg-gray-100'>
        <div className="container">
          <div className="sm:py-10 py-6">
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl font-500 text-center whitespace-nowrap text-gray-700 mb-6'>Argon Spare Parts</h2>
            <div className='mb-10'>
              <h5 className='md:text-base text-sm text-gray-600 mb-4'>Through our global equipment network, Argon provides an extensive variety of parts, components, and supplies for all types of imaging equipment. We guarantee our parts are thoroughly inspected and determined to be high quality for your replacement needs.</h5>
              <h5 className='md:text-base text-sm text-gray-600 mb-4'>At Argon we stock it all, including cables, transformers, control panels, control boards, equipment covers, monitors, keyboards, hard drives, power supplies, supply boards, switches, cooling fans, assembly kits, motors, adapters, sensors, brackets, and so much more!</h5>
              <h5 className='md:text-base text-sm text-gray-600'>We keep medical imaging equipment parts in stock. We offer replacement parts for equipment such as Refurbished CT Scanner systems.</h5>
            </div>
            <div className="grid lg:grid-cols-2 gap-4 justify-center">
              <div className="" data-aos="zoom-in" data-aos-duration="1500">
                <img src={require("../../assets/images/spare_parts.jpg")} className='rounded-md' alt="" />
              </div>
              <div className="content rounded-md flex flex-col justify-center px-6" data-aos="zoom-out" data-aos-duration="1500">
                <h2 className='lg:text-2xl md:text-xl text-lg text-gray-700 font-600 mb-2'>Ct Tubes</h2>
                <h5 className='md:text-base text-sm text-gray-600 mb-5'>Argon Medical Systems has quality used CT tubes available with warranty and installation, at prices you can afford.</h5>
                <h2 className='lg:text-2xl md:text-xl text-lg text-gray-700 font-600 mb-2.5'>Toshiba Tubes</h2>
                <div className="detail">
                  <div className='flex flex-col gap-2.5 bg-gray-50 shadow-sm cursor-pointer p-4 rounded-md'>
                    <h2 className='text-gray-600 max-sm:text-sm font-600 mb-1'>2.0 MHU – Xvision; Xpress; Asteion; Auklet; Xpeed, Alexion</h2>
                    <h2 className='text-gray-600 max-sm:text-sm font-600 mb-1'>3.5 MhU – Xvision; Xpress GX/HS1; Asteion VF, Alexion 16</h2>
                    <h2 className='text-gray-600 max-sm:text-sm font-600 mb-1'>4.0 MHU – Asteion VR, VI, Multi (4-slice), Asteion Super 4, Activion 16</h2>
                    <h2 className='text-gray-600 max-sm:text-sm font-600 mb-1'>6.5 MHU – Xpress SX; Asteion</h2>
                    <h2 className='text-gray-600 max-sm:text-sm font-600 mb-1'>Aquilion Single, 4, 8, 16, 32, 64</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SpareParts
