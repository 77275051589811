import React from 'react'
import { cn } from '../../lib/utils'

const Backdrop = ({ className, onClick }) => {
  return (
    <div className={cn(`fixed inset-0 bg-black/30`, className)} onClick={onClick}></div>
  )
}

export default Backdrop
