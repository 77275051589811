import React, { useState } from 'react'
import PageHeader from '../../components/common/PageHeader'
import { serviceData } from '../../data'
import { Link } from 'react-router-dom'

const Services = () => {
  const [activeTab, setActiveTab] = useState(serviceData[0])
  console.log(activeTab)
  return (
    <section className='welcome-section'>
      <div className="md:py-20 py-8 text-center text-white relative bg-[url('../../../src/assets/images/service-bg.png')] bg-black/25 bg-blend-darken bg-cover bg-top">
        <h1 className='lg:text-4xl md:text-3xl text-2xl text-white font-500 mb-2.5'>Services</h1>
        <div className="flex gap-2.5 justify-center max-md:text-sm">
          <h4><Link to={"/"}>Home</Link></h4>
          <h4>></h4>
          <h4>Services</h4>
        </div>
      </div>
      <div className='bg-gray-100'>
        <div className="container">
          <div className="sm:py-10 py-6 text-justify">
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl font-500 text-center whitespace-nowrap text-gray-700 sm:mb-10 mb-6'>Our Services</h2>
            <div className="grid lg:grid-cols-5 gap-4 justify-center">
              <div className="content lg:col-span-3 rounded-md flex flex-col px-6" data-aos="zoom-out" data-aos-duration="1500">
                <h5 className='md:text-base text-sm text-gray-600 mb-4'>Argon Medical Systems offers a full range of value added services designed to simplify and streamline all phases of your project from acquisition through installation, and maintenance. Our experienced staff will partner with you to determine the services and products that best suit your clinical and budgetary requirements.</h5>
                <h5 className='md:text-base text-sm text-gray-600 mb-4'>At Argon Medical Systems, great service is what we do. Our comprehensive expertise in all major imaging systems allows us to service the latest CT, Scanner System. We also realize that, while there is a technical side to this business, it’s the personal side of working with our customers every day that truly makes the difference. Our friendly and experienced service team is dedicated to helping you whenever you need us. We’re proud to offer this level of personalized service standard for every customer.</h5>
                <h5 className='md:text-base text-sm text-gray-600 mb-6'>Our highly-skilled technicians provide a range of services, maintenance and repairs to a vast range of Medical Imaging Equipment, as well as sourcing and supply of equipment parts where required.</h5>
              </div>
              <div className="relative lg:col-span-2">
                <img src={require("../../assets/images/services.png")} className='w-full max-w-[80%] mx-auto' alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="detail my-8">
          <div class="flex w-max mx-auto gap-2.5 bg-gray-100 rounded-lg transition p-2.5">
            {
              serviceData.map((service) =>
                <button type="button" onClick={() => setActiveTab(service)} className={`py-3 px-4 ${activeTab.tabName == service.tabName ? "bg-white" : "bg-transparent text-gray-500"} text-sm font-medium rounded-lg`}>
                  {console.log(service.tabName)}
                  {service.tabName}
                </button>
              )
            }
          </div>
          <div className='py-6'>
            <div className="grid lg:grid-cols-2 gap-4 justify-center">
              <div className="relative">
                <img src={require(`../../assets/images/${activeTab.img}`)} className='w-[90%] mx-auto' alt="" />
              </div>
              <div className="content rounded-md flex flex-col max-lg:text-center py-4">
                <h2 className='lg:text-2xl md:text-xl text-lg text-gray-700 font-600 mb-2'>{activeTab.title}</h2>
                <div className='mb-5'>
                  {
                    activeTab.description.map((data) =>
                      <h5 className='md:text-base text-sm text-gray-600'>{data}</h5>
                    )
                  }
                </div>
                {
                  activeTab.benifit &&
                  <>
                    <h2 className='lg:text-2xl md:text-xl text-lg text-gray-700 font-600 mb-2'>Benifits:</h2>
                    <h5 className='md:text-base text-sm text-gray-600 font-500 mb-2'>{activeTab.benifit.title}</h5>
                    {
                      activeTab.benifit.benifits.map((data) =>
                        <h5 className='text-sm text-gray-600 text-start font-400 mb-2'><span className='me-2'>></span>{data}</h5>
                      )
                    }
                  </>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
