import React from 'react'
import { Link } from 'react-router-dom'
import IconLocation from '../svg/IconLocation'
import IconCall from '../svg/IconCall'
import IconMail from '../svg/IconMail'
import IconSend from '../svg/IconSend'

const Footer = () => {
  return (
    <>
      <section className='py-10 bg-theme'>
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:gap-8 gap-6">
            <div className="newsletter max-lg:col-span-2 max-md:col-span-1">
              <h5 className='text-white sm:text-base text-sm mb-5'>Discover the Latest in Cutting-Edge Medical Equipment - Subscribe Now!</h5>
              <div className="flex mb-4">
                <input type="text" className='p-2 w-full sm:text-base rounded-s-md text-sm max-w-[300px]' placeholder='Enter your email address...' />
                <div className="icon w-10 border-s border-gray-400 flex justify-center rounded-e-md items-center bg-orange-500"><IconSend className="w-5 text-white" /></div>
              </div>
            </div>
            <div className="navigation">
              <div className="grid grid-cols-2">
                <ul className='flex flex-col gap-2'>
                  <li>
                    <Link to={"/"} className='text-white sm:text-base text-sm pb-1'>> Home</Link>
                  </li>
                  <li>
                    <Link to={"/products"} className='text-white sm:text-base text-sm pb-1'>> Products</Link>
                  </li>
                  <li>
                    <Link to={"/services"} className='text-white sm:text-base text-sm pb-1'>> Services</Link>
                  </li>
                  <li>
                    <Link to={"/injector"} className='text-white sm:text-base text-sm pb-1'>> Injectors</Link>
                  </li>


                </ul>
                <ul className='flex flex-col gap-2'>
                  <li>
                    <Link to={"/about"} className='text-white sm:text-base text-sm pb-1'>> About Us</Link>
                  </li>
                  <li>
                    <Link to={"/spare-parts"} className='text-white sm:text-base text-sm pb-1'>> Spare Parts</Link>
                  </li>
                  <li>
                    <Link to={"/contact-us"} className='text-white sm:text-base text-sm pb-1'>> Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col sm:gap-4 gap-2.5">
              <div className="flex gap-2.5 items-start">
                <IconLocation className="w-6 flex-shrink-0 text-white" />
                <h2 className='xl:text-base text-sm text-white'>412-413, Radhika Optima Near Yamuna chowk, Mota Varachha Surat, Gujarat 394101</h2>
              </div>
              <div className="flex gap-2.5 items-start">
                <IconCall className="w-6 flex-shrink-0 text-white" />
                <h2 className='xl:text-base text-sm text-white'>87581 50698, 79903 29076</h2>
              </div>
              <div className="flex gap-2.5 items-start">
                <IconMail className="w-6 flex-shrink-0 text-white" />
                <h2 className='xl:text-base text-sm text-white'>argonmedicalsystemsllp@gmail.com</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright py-2 bg-gray-800 text-white text-center sm:text-sm xsm:text-xs text-[10px]">
        Copyright © 2019 Argon Medical Systems LLP. All right reserved.
      </div>
    </>
  )
}

export default Footer
