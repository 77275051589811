import React from 'react'
import { Button } from '../../components/ui/Button'
import { useLocation } from 'react-router-dom'

const ProductDetail = () => {
  const location = useLocation();
  const productData = location.state;
  console.log("data", productData)
  return (
    <div className="container">
      <div className="grid lg:grid-cols-2 gap-4 justify-center">
        <div className="" data-aos="zoom-in" data-aos-duration="1500">
          <img src={productData?.img} className='rounded-md' alt="" />
        </div>
        {
          productData?.id > 3 &&
          <div className="content rounded-md flex flex-col justify-center px-6" data-aos="zoom-out" data-aos-duration="1500">
            <h2 className='lg:text-3xl md:text-2xl max-lg:text-center text-xl text-gray-700 font-600 sm:mb-4 mb-2.5' >{productData.title}</h2>
            {
              productData.subTitle &&
              <h2 className='lg:text-xl md:text-lg max-lg:text-center text-base text-gray-700 font-600 sm:mb-4 mb-2.5' >{productData.subTitle}</h2>
            }
            <h5 className='md:text-base text-sm max-lg:text-center text-gray-600 mb-6'>{productData.description}</h5>
            <div className="detail grid md:grid-cols-2 gap-6 mb-8">
              <div className='flex flex-col gap-2.5 max-md:text-center max-xsm:text-start bg-gray-50 shadow-sm cursor-pointer p-4 rounded-md'>
                <div className=''>
                  <h2 className='text-gray-600 font-600 mb-1'>Gantry aperture :</h2>
                  <p className='text-sm text-gray-600'>{productData.gantryAperture} mm in diameter</p>
                </div>
                <div className=''>
                  <h2 className='text-gray-600 font-600 mb-1'>Scan time :</h2>
                  <p className='text-sm text-gray-600'>{productData.scanTime}</p>
                </div>
                {
                  productData.capacity &&
                  <div className=''>
                    <h2 className='text-gray-600 font-600 mb-1'>X-ray tube heating capacity :</h2>
                    <p className='text-sm text-gray-600'>{productData.capacity} MHU</p>
                  </div>
                }
                {
                  productData.heatingCapacity &&
                  <div className=''>
                    <h2 className='text-gray-600 font-600 mb-1'>X-ray tube heating capacity</h2>
                    <p className='text-sm text-gray-600'>{productData.heatingCapacity}</p>
                  </div>
                }
                {
                  productData.color3dImage &&
                  <div className=''>
                    <h2 className='text-gray-600 font-600 mb-1'>3D color image</h2>
                    <p className='text-sm text-gray-600'>{productData.color3dImage}</p>
                  </div>
                }
                {
                  productData.aidr3D &&
                  <div className=''>
                    <h2 className='text-gray-600 font-600 mb-1'>AIDR 3D(Adaptive Iterative Dose Reduction 3D)</h2>
                  </div>
                }
              </div>
              <div className='flex flex-col gap-2.5 max-md:text-center max-xsm:text-start bg-gray-50 shadow-sm cursor-pointer p-4 rounded-md'>
                <div className=''>
                  <h2 className='text-gray-600 font-600 mb-1'>Gantry tilt angle :</h2>
                  <p className='text-sm text-gray-600'>{productData.gantryTiltAngle}</p>
                </div>
                <div className=''>
                  <h2 className='text-gray-600 font-600 mb-1'>X-ray tube voltage :</h2>
                  <p className='text-sm text-gray-600'>{productData.voltage}</p>
                </div>
                <div className=''>
                  <h2 className='text-gray-600 font-600 mb-1'>Image recon. Time :</h2>
                  <p className='text-sm text-gray-600'>{productData.imageReconTime}</p>
                </div>
                {
                  productData.detectionSystem &&
                  <div className=''>
                    <h2 className='text-gray-600 font-600 mb-1'>Detection system :</h2>
                    <p className='text-sm text-gray-600'>{productData.detectionSystem}</p>
                  </div>
                }
                {
                  productData.sureExposure3D &&
                  <div className=''>
                    <h2 className='text-gray-600 font-600 mb-1'>Sure Exposure 3D</h2>
                  </div>
                }
              </div>
            </div>
          </div>
        }
        {
          productData?.id <= 3 &&
          <div className="content rounded-md flex flex-col justify-center px-6" data-aos="zoom-out" data-aos-duration="1500">
            <h2 className='lg:text-3xl md:text-2xl max-lg:text-center text-xl text-gray-700 font-600 sm:mb-4 mb-2.5' >{productData.title}</h2>
            {
              productData.description && productData.description.map((data) =>
                <h5 className='md:text-base text-sm text-gray-600 mb-6'>{data}</h5>)
            }
          </div>
        }
      </div>
      {
        productData?.id <= 3 &&
        <>
          <div className="detail grid grid-cols-2 gap-6 my-8">
            <div className='flex flex-col gap-2.5 bg-gray-50 shadow-sm cursor-pointer p-4 rounded-md'>
              {
                productData.features && productData.features.slice(0, productData.features.length / 2).map((feature) => <h2 className='text-gray-600 max-sm:text-sm font-600 mb-1'>{feature}</h2>)
              }
            </div>
            <div className='flex flex-col gap-2.5 bg-gray-50 shadow-sm cursor-pointer p-4 rounded-md'>
              {
                productData.features && productData.features.slice(productData.features.length / 2).map((feature) => <h2 className='text-gray-600 max-sm:text-sm font-600 mb-1'>{feature}</h2>)
              }
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default ProductDetail
