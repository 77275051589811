import React from 'react'

const IconCall = (props) => {
  return (
    <svg {...props} viewBox="0 0 69 69" fill="none">
      <path d="M64.763 48.7183C64.6487 48.6234 51.7198 39.4119 48.2288 39.9877C46.5448 40.2852 45.5831 41.4345 43.6533 43.7331C43.1197 44.373 42.5741 45.0027 42.0167 45.6219C40.797 45.2246 39.6073 44.7403 38.4567 44.1729C32.5174 41.2814 27.7186 36.4826 24.8271 30.5433C24.2597 29.3927 23.7754 28.203 23.3781 26.9833C24.012 26.4033 24.9004 25.6551 25.2799 25.3359C27.5677 23.4169 28.7148 22.453 29.0123 20.7668C29.6226 17.2759 20.3766 4.35131 20.2817 4.23487C19.8606 3.63772 19.3122 3.14149 18.6761 2.78203C18.0399 2.42257 17.3319 2.20883 16.6031 2.15625C12.8556 2.15625 2.15625 16.0339 2.15625 18.3734C2.15625 18.5092 2.35247 32.3179 19.3804 49.639C36.6821 66.6475 50.4908 66.8438 50.6266 66.8438C52.964 66.8438 66.8438 56.1444 66.8438 52.3969C66.7906 51.668 66.5764 50.96 66.2166 50.3238C65.8568 49.6877 65.3603 49.1394 64.763 48.7183ZM50.3873 62.5183C48.5156 62.3587 36.915 60.83 22.425 46.5966C8.12259 32.0354 6.63262 20.4154 6.48384 18.6149C9.31021 14.1787 12.7236 10.1452 16.6312 6.624C16.7174 6.71025 16.8317 6.83962 16.9783 7.00781C19.9751 11.0987 22.5567 15.4781 24.6847 20.0812C23.9927 20.7774 23.2613 21.4332 22.494 22.0455C21.3042 22.9521 20.2116 23.9797 19.2338 25.1117C19.0683 25.3438 18.9505 25.6065 18.8872 25.8844C18.8239 26.1624 18.8164 26.4501 18.865 26.731C19.3214 28.7077 20.0202 30.6204 20.9458 32.4257C24.2619 39.2352 29.7644 44.7369 36.5743 48.052C38.3792 48.9789 40.292 49.6786 42.269 50.135C42.5499 50.1836 42.8376 50.1761 43.1156 50.1128C43.3935 50.0495 43.6562 49.9317 43.8883 49.7662C45.0243 48.7845 46.0555 47.6875 46.9653 46.4931C47.6423 45.6866 48.5458 44.6107 48.8887 44.3066C53.5034 46.4326 57.8926 49.0173 61.99 52.0217C62.169 52.1726 62.2962 52.2891 62.3803 52.3645C58.8589 56.2734 54.8246 59.6875 50.3873 62.514V62.5183Z" fill="currentcolor" />
      <path d="M36.6562 19.4062C40.0864 19.4097 43.3752 20.7738 45.8007 23.1993C48.2262 25.6248 49.5903 28.9136 49.5938 32.3438C49.5938 32.9156 49.8209 33.4641 50.2253 33.8684C50.6297 34.2728 51.1781 34.5 51.75 34.5C52.3219 34.5 52.8703 34.2728 53.2747 33.8684C53.6791 33.4641 53.9062 32.9156 53.9062 32.3438C53.9011 27.7703 52.0821 23.3857 48.8482 20.1518C45.6143 16.9179 41.2297 15.0989 36.6562 15.0938C36.0844 15.0938 35.5359 15.3209 35.1315 15.7253C34.7272 16.1297 34.5 16.6781 34.5 17.25C34.5 17.8219 34.7272 18.3703 35.1315 18.7747C35.5359 19.1791 36.0844 19.4062 36.6562 19.4062Z" fill="currentcolor" />
      <path d="M36.6562 8.625C42.9446 8.63242 48.9732 11.1337 53.4197 15.5803C57.8663 20.0268 60.3676 26.0554 60.375 32.3438C60.375 32.9156 60.6022 33.4641 61.0065 33.8684C61.4109 34.2728 61.9594 34.5 62.5312 34.5C63.1031 34.5 63.6516 34.2728 64.056 33.8684C64.4603 33.4641 64.6875 32.9156 64.6875 32.3438C64.6789 24.912 61.7229 17.7871 56.4679 12.5321C51.2129 7.27709 44.088 4.32106 36.6562 4.3125C36.0844 4.3125 35.5359 4.53968 35.1315 4.94405C34.7272 5.34843 34.5 5.89688 34.5 6.46875C34.5 7.04062 34.7272 7.58907 35.1315 7.99345C35.5359 8.39782 36.0844 8.625 36.6562 8.625Z" fill="currentcolor" />
    </svg>

  )
}

export default IconCall
