import React from 'react'

const IconClose = (props) => {
  return (
    <svg {...props} viewBox="0 0 48 48" fill="none" >
      <g clipPath="url(#clip0_53209_5)">
        <path d="M24 0C10.7606 0 0 10.7606 0 24C0 37.2394 10.7606 48 24 48C37.2394 48 48 37.2394 48 24C48 10.7606 37.2394 0 24 0ZM24 45.5775C12.1127 45.5775 2.42254 35.8873 2.42254 24C2.42254 12.1127 12.1127 2.42254 24 2.42254C35.8873 2.42254 45.5775 12.1127 45.5775 24C45.5775 35.8873 35.8873 45.5775 24 45.5775Z" fill="currentcolor" />
        <path d="M25.6901 23.8873L32.7324 16.8451C33.1831 16.3944 33.1831 15.6056 32.7324 15.1549C32.2817 14.7042 31.493 14.7042 31.0423 15.1549L24 22.1972L16.9577 15.1549C16.507 14.7042 15.7183 14.7042 15.2676 15.1549C14.8169 15.6056 14.8169 16.3944 15.2676 16.8451L22.3099 23.8873L15.2676 30.8732C14.8169 31.3239 14.8169 32.1127 15.2676 32.5634C15.493 32.7887 15.831 32.9014 16.1127 32.9014C16.3944 32.9014 16.7324 32.7887 16.9577 32.5634L24 25.5211L31.0423 32.5634C31.2676 32.7887 31.6056 32.9014 31.8873 32.9014C32.169 32.9014 32.507 32.7887 32.7324 32.5634C33.1831 32.1127 33.1831 31.3239 32.7324 30.8732L25.6901 23.8873Z" fill="currentcolor" />
      </g>
    </svg>
  )
}

export default IconClose
