import React from 'react'
import PageHeader from '../../components/common/PageHeader'
import { productData } from '../../data'
import { Link, useNavigate } from 'react-router-dom'

const AllProducts = () => {
  const navigate = useNavigate();
  const handleNavigate = (url, data) => {
    navigate(url, { state: data })
  }
  return (
    <section className='welcome-section'>
      <PageHeader title={"Products"} subNav={"Products"} />
      <div className="md:py-20 pt-8 bg-gray-100">
        <section id="Projects"
          class="container w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-8 mt-10 mb-5">
          {
            productData.map((product) =>
              <div class="bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
                <div onClick={() => handleNavigate(`/product/${product.id}`, product)} className='cursor-pointer'>
                  <img src={product?.img}
                    alt="Product" class="h-96 w-96 object-cover rounded-t-xl" />
                  <div class="px-4 py-3 w-72">
                    <span class="text-gray-400 mr-3 uppercase text-xs">TOSHIBA</span>
                    <p class="text-lg font-bold text-black truncate block capitalize">{product.title}</p>
                  </div>
                </div>
              </div>
            )
          }

        </section>
      </div>
    </section>
  )
}

export default AllProducts
