import React from 'react'
import IconSell from '../../../components/svg/IconSell'
import IconBuy from '../../../components/svg/IconBuy'
import IconPartAndTube from '../../../components/svg/IconPartAndTube'

const WhatWeDo = () => {
  return (
    <section className='what-we-do bg-gray-100'>
      <div className="container">
        <div className="lg:py-12 sm:py-8 py-6">
          <div className="flex justify-center items-center gap-6 sm:mb-10 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>What we do</h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <div className="grid lg:grid-cols-3 sm:gap-6 gap-4">
            <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="zoom-in" data-aos-duration="2000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconSell className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>SELL EQUIPMENT</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>We also buy second-hand Medical Imaging Equipment, provide an easy and efficient process as well as giving you the best offer.</h5>
            </div>
            <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="zoom-in" data-aos-duration="2000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconBuy className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>BUY EQUIPMENT</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>We sell second-hand Medical Imaging Equipment both within India and worldwide. Argon Medical Systems provide pre-owned or refurbished systems with full service support and Standard warranty to our customers.</h5>
            </div>
            <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="zoom-in" data-aos-duration="2000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconPartAndTube className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>PARTS & TUBES</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>We sell a range parts from a number of different brands for a large variety of Medical Imaging Equipment. The State of the art warehouse, having 2 Lakh plus parts to serve you. Our warehouses works for 24 X 7.</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatWeDo
