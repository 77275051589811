import React from 'react'

const IconRight = (props) => {
  return (
    <svg {...props} viewBox="0 0 45 45" fill="none">
      <path d="M35.0008 20.7441L14.9752 0.718897C14.512 0.255364 13.8937 0 13.2345 0C12.5752 0 11.9569 0.255364 11.4938 0.718897L10.019 2.19327C9.05939 3.154 9.05939 4.71545 10.019 5.67471L26.835 22.4907L10.0004 39.3253C9.53719 39.7888 9.28146 40.4067 9.28146 41.0656C9.28146 41.7253 9.53719 42.3432 10.0004 42.8071L11.4751 44.2811C11.9386 44.7446 12.5566 45 13.2158 45C13.8751 45 14.4934 44.7446 14.9565 44.2811L35.0008 24.2376C35.465 23.7726 35.72 23.1518 35.7186 22.4918C35.72 21.8292 35.465 21.2087 35.0008 20.7441Z" fill="currentcolor" />
    </svg>

  )
}

export default IconRight
