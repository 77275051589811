import React from 'react'

const IconService = (props) => {
  return (
    <svg {...props} viewBox="0 0 48 48" fill="none">
      <path d="M26.25 9.75V5.25C26.25 5.05109 26.171 4.86032 26.0303 4.71967C25.8897 4.57902 25.6989 4.5 25.5 4.5H9C8.80109 4.5 8.61032 4.57902 8.46967 4.71967C8.32902 4.86032 8.25 5.05109 8.25 5.25V9.75C8.25 9.94891 8.32902 10.1397 8.46967 10.2803C8.61032 10.421 8.80109 10.5 9 10.5H25.5C25.6989 10.5 25.8897 10.421 26.0303 10.2803C26.171 10.1397 26.25 9.94891 26.25 9.75ZM24.75 9H9.75V6H24.75V9ZM5.25 13.5V22.5C5.25 22.6989 5.32902 22.8897 5.46967 23.0303C5.61032 23.171 5.80109 23.25 6 23.25H15C15.1989 23.25 15.3897 23.171 15.5303 23.0303C15.671 22.8897 15.75 22.6989 15.75 22.5V18.1815L14.25 19.6815V21.75H6.75V14.25H13.3185L14.8185 12.75H6C5.80109 12.75 5.61032 12.829 5.46967 12.9697C5.32902 13.1103 5.25 13.3011 5.25 13.5Z" fill="black" />
      <path d="M16.7198 12.9697L11.25 18.4395L9.53025 16.7197L8.46975 17.7802L10.7198 20.0302C10.8604 20.1708 11.0511 20.2498 11.25 20.2498C11.4489 20.2498 11.6396 20.1708 11.7803 20.0302L17.7803 14.0302L16.7198 12.9697ZM5.25 35.25C5.25 35.4489 5.32902 35.6397 5.46967 35.7803C5.61032 35.921 5.80109 36 6 36H15C15.1989 36 15.3897 35.921 15.5303 35.7803C15.671 35.6397 15.75 35.4489 15.75 35.25V30.9315L14.25 32.4315V34.5H6.75V27H13.3185L14.8185 25.5H6C5.80109 25.5 5.61032 25.579 5.46967 25.7196C5.32902 25.8603 5.25 26.0511 5.25 26.25V35.25Z" fill="black" />
      <path d="M9.53023 29.4697L8.46973 30.5303L10.7197 32.7803C10.8604 32.9209 11.0511 32.9998 11.25 32.9998C11.4488 32.9998 11.6396 32.9209 11.7802 32.7803L17.7802 26.7803L16.7197 25.7197L11.25 31.1895L9.53023 29.4697ZM19.5 18H29.25V19.5H19.5V18ZM19.5 14.25H29.25V15.75H19.5V14.25ZM19.5 27H24V28.5H19.5V27ZM19.5 30.75H24V32.25H19.5V30.75Z" fill="black" />
      <path d="M3 45V3H31.5V19.017C32.006 18.84 32.506 18.6468 33 18.4373V2.25C33 2.05109 32.921 1.86032 32.7803 1.71967C32.6397 1.57902 32.4489 1.5 32.25 1.5H2.25C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V45.75C1.5 45.9489 1.57902 46.1397 1.71967 46.2803C1.86032 46.421 2.05109 46.5 2.25 46.5H32.25C32.329 46.4969 32.407 46.4812 32.481 46.4535C31.7731 46.0158 31.096 45.5302 30.4545 45H3Z" fill="black" />
      <path d="M45.8318 21.7543C42.5151 21.3947 39.2976 20.4047 36.3525 18.8375C36.244 18.7797 36.1229 18.7495 36 18.7495C35.8771 18.7495 35.756 18.7797 35.6475 18.8375C32.7024 20.4047 29.4849 21.3947 26.1682 21.7543C25.9846 21.7744 25.8148 21.8616 25.6915 21.9992C25.5682 22.1368 25.5 22.315 25.5 22.4998V31.3835C25.5207 34.6223 26.5021 37.7821 28.3198 40.4628C30.1376 43.1434 32.71 45.2243 35.7113 46.442C35.8027 46.4802 35.9009 46.4998 36 46.4998C36.0991 46.4998 36.1973 46.4802 36.2887 46.442C39.29 45.2243 41.8624 43.1434 43.6802 40.4628C45.4979 37.7821 46.4793 34.6223 46.5 31.3835V22.4998C46.5 22.315 46.4318 22.1368 46.3085 21.9992C46.1852 21.8616 46.0154 21.7744 45.8318 21.7543ZM45 31.3835C44.98 34.2726 44.1179 37.0932 42.5194 39.4999C40.9209 41.9066 38.6553 43.795 36 44.9338C33.3447 43.795 31.0791 41.9066 29.4806 39.4999C27.8821 37.0932 27.02 34.2726 27 31.3835V23.1635C30.1391 22.7466 33.1834 21.7938 36 20.3465C38.8166 21.7939 41.8608 22.7467 45 23.1635V31.3835Z" fill="black" />
      <path d="M29.7803 30.9697L28.7197 32.0302L33.2197 36.5302C33.3604 36.6708 33.5511 36.7498 33.75 36.7498C33.9489 36.7498 34.1396 36.6708 34.2803 36.5302L43.2803 27.5302L42.2197 26.4697L33.75 34.9395L29.7803 30.9697ZM18.75 41.25H24V42.75H18.75V41.25ZM12 38.25H24V39.75H12V38.25Z" fill="black" />
    </svg>

  )
}

export default IconService
