import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

const sliders = [
  { img: "slider1.webp", title1: "Refurbished", title2: "CT Scanner" },
  { img: "slider2.webp", title1: "Parts", title2: "& Support" },
  { img: "slider3.jpg", title1: "Service", title2: "& Coverage" },
]

const HeroSection = () => {
  return (
    <section className='hero-section mx-auto'>
      <div>
        <Swiper
          modules={[Navigation, Autoplay]}
          className="mySwiper sm:aspect-[16/7] aspect-[16/12]"
          autoplay
          loop
        >
          {
            sliders.map((item, index) =>
              <SwiperSlide key={index}>
                <div className="relative w-full h-full">
                  <img src={require(`../../../assets/images/${item.img}`)} className='w-full h-full object-cover object-right' alt="" />
                  <div className="content absolute top-1/2 -translate-y-1/2 left-0 flex flex-col lg:gap-2 bg-black/30 backdrop-blur-md lg:p-10 sm:p-2 p-1 md:px-8 sm:px-6 px-4 sm:rounded-2xl rounded-md !rounded-s-none">
                    <h4 className='flex gap-4 rounded-lg font-600 text-white lg:text-4xl md:text-xl sm:text-base text-sm'>{item.title1}</h4>
                    <h4 className='flex gap-4 rounded-lg font-600 text-white lg:text-4xl md:text-xl sm:text-base text-sm'>{item.title2}</h4>
                  </div>
                </div>
              </SwiperSlide>
            )
          }
        </Swiper>
      </div>
    </section>
  )
}

export default HeroSection
