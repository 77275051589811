import React from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { productData } from '../../../data'

const sliders = [
  { img: "slider1.jpg", title1: "Refurbished", title2: "CT Scanner" },
  { img: "slider2.webp", title1: "services", title2: "& repairs" },
  { img: "slider3.jpg", title1: "service  ", title2: "& coverage" },
  { img: "slider2.webp", title1: "services", title2: "& repairs" },
]

const OurProduct = () => {
  return (
    <section className='our-product'>
      <div className="container">
        <div className="lg:py-12 sm:py-8 py-6">
          <div className="flex justify-center items-center gap-6 sm:mb-10 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>Latest Products</h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <div className="product-slider">
            <Swiper
              modules={[Navigation]}
              className="productSwiper"
              navigation
              loop
              slidesPerView={1}
              spaceBetween={30}
              breakpoints={{
                992: {
                  slidesPerView: 3
                },
                768: {
                  slidesPerView: 2
                },
              }}
            >
              {
                productData.map((item, index) =>
                  <SwiperSlide key={index}>
                    <div className="relative w-full h-full cursor-pointer" data-aos="fade-left" data-aos-duration="2000">
                      <img src={item.img} className='w-full h-full object-cover object-center' alt="" />
                    </div>
                  </SwiperSlide>
                )
              }
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurProduct
