import React from 'react'
import { Link } from 'react-router-dom'

const PageHeader = ({ title, subNav }) => {
  return (
    <div className="md:py-20 py-8 text-center text-white relative bg-[url('../../../src/assets/images/aboutus-bg2.jpg')] bg-black/25 bg-blend-darken bg-cover bg-center">
      <h1 className='lg:text-4xl md:text-3xl text-2xl text-white font-500 mb-2.5'>{title}</h1>
      <div className="flex gap-2.5 justify-center max-md:text-sm">
        <h4><Link to={"/"}>Home</Link></h4>
        <h4>></h4>
        <h4>{subNav}</h4>
      </div>
    </div>
  )
}

export default PageHeader
