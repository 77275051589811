import React from 'react'
import IconSupport from '../../../components/svg/IconSupport'
import IconService from '../../../components/svg/IconService'
import IconMaintenance from '../../../components/svg/IconMaintenance'
import Lottie from 'lottie-react'
import services from "../../../assets/lottie/services.json"

const OurService = () => {
  return (
    <section className='our-service bg-gray-100'>
      <div className="container">
        <div className="lg:py-12 sm:py-8 py-6">
          <div className="flex justify-center items-center gap-6 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>Our Services</h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <div className="grid lg:grid-cols-5 lg:gap-6 items-center">
            <div className="services lg:col-span-3">
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconSupport className="lg:w-20 w-16 p-2 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>Technical Support Team</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>Argon have technical team which is available for 24 hours.</h5>
                </div>
              </div>
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconService className="lg:w-20 w-16 p-2 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>Full Service Coverage</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>With the Full Coverage Agreement, Argon provides all parts for your system.</h5>
                </div>
              </div>
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconMaintenance className="lg:w-20 w-16 p-2 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>
                    Preventive Maintenance</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>Argon provides Preventive maintenance agreement to its customer.</h5>
                </div>
              </div>
            </div>
            <div className="image max-lg:max-w-[80%] mx-auto col-span-2">
              <Lottie animationData={services} loop={true} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurService
