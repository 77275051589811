import React from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const sliders = [
  {
    img: "review1.webp",
    name: "Dr. Ankit Patel",
    status: "MD Radiologist (Gujarat)",
    comment: "I am very pleased with the service I received from Argon Medical Systems. They do preventative maintenance on all of our machinery. They always come on time. The turnaround time is great!! I really appreciate their service and hope that they continue to support us."
  },
  {
    img: "review2.jpeg",
    name: "Dr. Rakesh Shivhare",
    status: "MD Radiologist (Madhya Pradesh)",
    comment: "I have ordered a CT Scanner System and i must admit that making business with Argon it is good choice.They reply very quick and very correct supplier"
  },
  {
    img: "review3.jpeg",
    name: "Dr. Ketan Patel",
    status: "MD Radiologist (Gujarat)",
    comment: "Argon Medical Systems is my go-to for all things medical imaging. Their refurbished equipment is top-notch and their service is unbeatable. From their quick responses to their punctual maintenance visits, they never disappoint. Highly recommended!"
  },
  {
    img: "review4.jpeg",
    name: "Dr. Sameer Garg",
    status: "MD Radiologist (Punjab)",
    comment: "Absolutely lifesaving for our facility! Prompt service and high-quality equipment from Argon Medical Systems have made all the difference. Their support is greatly appreciated, and we highly recommend them to others in the industry."
  },
  {
    img: "review2.jpeg",
    name: "Dr. Aameer",
    status: "MD Radiologist (Punjab)",
    comment: "Argon Medical Systems has exceeded my expectations in every way. From their impeccable service to their affordable prices, they've become my trusted partner in medical imaging solutions. I can't recommend them enough!"
  },
]

const WhatOurCustomerSay = () => {
  return (
    <section className='what-our-customer-say'>
      <div className="container">
        <div className="lg:py-12 sm:py-8 py-6">
          <div className="flex justify-center items-center gap-6 sm:mb-10 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>What Our Customer Says ?
            </h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <div className="product-slider">
            <Swiper
              modules={[Navigation]}
              className="productSwiper"
              navigation
              loop
              slidesPerView={1}
              spaceBetween={30}
              breakpoints={{
                992: {
                  slidesPerView: 3
                },
                768: {
                  slidesPerView: 2
                },
              }}
            >
              {
                sliders.map((item, index) =>
                  <SwiperSlide key={index}>
                    <div className="relative cursor-pointer flex flex-col gap-4 bg-gray-200 h-full rounded-lg p-6" data-aos="fade-left" data-aos-duration="2000">
                      <div className="flex items-center justify-center gap-4">
                        <img src={require(`../../../assets/images/${item.img}`)} className='w-[60px] aspect-square object-cover object-top rounded-full' alt="" />
                        <div className="text">
                          <div className='text-lg font-600'>{item.name}</div>
                          <div className='text-sm text-gray-600'>{item.status}</div>
                        </div>
                      </div>
                      <div className='xl:text-base text-center sm:text-sm text-xs'><i>{item.comment}</i></div>
                    </div>
                  </SwiperSlide>
                )
              }
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatOurCustomerSay
