import React from 'react'
import IconVision from '../../components/svg/IconVision'
import IconMission from '../../components/svg/IconMission'
import IconValue from '../../components/svg/IconValue'
import PageHeader from '../../components/common/PageHeader'

const About = () => {
  return (
    <div className='about-section'>
      <PageHeader title="About Us" subNav="About Us" />
      <div className="container">
        <div className="bg-[url('../../../src/assets/images/bg.jpg')] bg-cover bg-bottom">
          <ul className='flex flex-col gap-5 lg:py-16 py-10 sm:max-w-[80%] mx-auto'>
            <li data-aos="fade-right" data-aos-duration="1000">
              <p className='lg:text-lg text-gray-600 font-500 leading-[22px] mb-1.5'>Affordable Medical Imaging Solutions</p>
              <h2 className='lg:text-base text-sm text-gray-600'>Argon is an organization specializing in offering affordable medical imaging equipment and providing impeccable after sales & maintenance service to our valuable customer.</h2>
            </li>
            <li data-aos="fade-right" data-aos-duration="1000">
              <p className='lg:text-lg text-gray-600 font-500 leading-[22px] mb-1.5'>Trusted Provider of Refurbished Toshiba CT Scanners in India</p>
              <h2 className='lg:text-base text-sm text-gray-600'>Dealing with refurbished Toshiba CT scanners from Japan. Argon is widely acclaimed by the medical fraternity in India for its outstanding service with the highest uptime and maintaining excellent customer relationship. Toshiba needs no introduction as a leading company, well known worldwide and especially in the medical industry for its high quality, innovative & reliable systems.</h2>
            </li>
            <li data-aos="fade-right" data-aos-duration="1000">
              <p className='lg:text-lg text-gray-600 font-500 leading-[22px] mb-1.5'>Building Trust in Healthcare: Argon's Preferred Service Approach</p>
              <h2 className='lg:text-base text-sm text-gray-600'>As a preferred service provider, we believe in building relations with the Doctors, Radiologists, Engineers, and the key people belonging to medical fraternity, who are instrumental in the establishment of hospitals & new medical facilities across the nation, through trust, integrity and emphasis on quality.</h2>
            </li>
            <li data-aos="fade-right" data-aos-duration="1000">
              <p className='lg:text-lg text-gray-600 font-500 leading-[22px] mb-1.5'>Your First Choice for Refurbished Equipment</p>
              <h2 className='lg:text-base text-sm text-gray-600'>Here at Argon Medical Systems, we hope that you will look to us as your first and best choice in the refurbished medical equipment industry. Our philosophy is simple: We pride ourselves on customer satisfaction and hold ourselves to the highest standard of customer service. We are committed to using our expertise in the industry to identify and provide the right medical equipment to our customers at the best price in a timely and professional manner.</h2>
            </li>
            <li data-aos="fade-right" data-aos-duration="1000">
              <p className='lg:text-lg text-gray-600 font-500 leading-[22px] mb-1.5'>Unparalleled Medical Equipment Solutions</p>
              <h2 className='lg:text-base text-sm text-gray-600'>With a large selection of fully reconditioned inventory, unique customer service programs, and a comprehensive knowledge of medical equipment technology, Argon provides an unparalleled solution to your medical needs.</h2>
            </li>
            <li data-aos="fade-right" data-aos-duration="1000">
              <p className='lg:text-lg text-gray-600 font-500 leading-[22px] mb-1.5'>Headquartered in Surat, Serving Nationwide</p>
              <h2 className='lg:text-base text-sm text-gray-600'>With a large selection of fully reconditioned inventory, unique customer service programs, and a comprehensive knowledge of medical equipment technology, Argon provides an unparalleled solution to your medical needs.</h2>
            </li>
          </ul>
        </div>
        <div className="lg:py-12 sm:py-8 py-6">
          <div className="flex justify-center items-center gap-6 sm:mb-10 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>Guiding Principles</h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <div className="grid lg:grid-cols-3 sm:gap-6 gap-4 bg-gray-100 p-8">
            <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="fade-left" data-aos-duration="1000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconVision className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>Vision</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>Our Vision is Growth, Innovation and Reach; Growth through Innovation in products, business models, and center of excellence in service support and Reach every town, hospitals across India including smaller and rural places.</h5>
            </div>
            <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="fade-left" data-aos-duration="1000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconMission className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>Mission Statement</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>We believe in treating our customers with respect and faith. We grow through creativity, invention and innovation. We integrate honesty, integrity and business ethics into all aspects of our business.
              </h5>
            </div>
            <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="fade-left" data-aos-duration="1000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconValue className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>Core Values</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>We sell a range parts from a number of different brands for a large variety of Medical Imaging Equipment. The State of the art warehouse, having 2 Lakh plus parts to serve you. Our warehouses works for 24 X 7.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
