import React from 'react'
import PageHeader from '../../components/common/PageHeader'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

const Injector = () => {
  const sliders = [
    { img: "nemoto_injector.png", title1: "Nemoto Injector" },
    { img: "medrad_injector.jpg", title1: "Medrad Injector" },
  ]
  return (
    <section className='welcome-section'>
      <PageHeader title={"Products"} subNav={"Injector"} />
      <div className='bg-gray-100'>
        <div className="container">
          <div className="sm:py-10 py-6">
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl font-500 text-center whitespace-nowrap text-gray-700 sm:mb-10 mb-6' >Pre-owned Injectors</h2>
            <div className="grid lg:grid-cols-5 gap-4 justify-center">
              <div className="lg:col-span-2 col-span-1 rounded-md overflow-hidden">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  className="mySwiper max-lg:w-[calc(100vw-200px)] max-md:w-[calc(100vw-140px)] max-sm:w-[calc(100vw-40px)] aspect-[9/10]"
                  autoplay
                  loop
                >
                  {
                    sliders.map((item, index) =>
                      <SwiperSlide key={index}>
                        <div className="relative w-full h-full">
                          <img src={require(`../../assets/images/${item.img}`)} className='w-full h-full object-cover object-center' alt="" />
                          <div className="content absolute top-1/2 -translate-y-1/2 left-0 flex flex-col lg:gap-2 bg-black/30 backdrop-blur-md lg:p-4 sm:p-2 p-1 md:px-8 sm:px-6 px-4 sm:rounded-2xl rounded-md !rounded-s-none">
                            <h4 className='flex gap-4 rounded-lg font-600 text-white lg:text-4xl md:text-xl sm:text-base text-sm'>{item.title1}</h4>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  }
                </Swiper>
              </div>
              <div className="content lg:col-span-3 rounded-md flex flex-col justify-center px-6" data-aos="zoom-out" data-aos-duration="1500">
                <h5 className='md:text-base text-sm max-lg:text-center text-gray-600 mb-4'>Argon Medical Systems provides high pressure injector used for CT with contrast .It features direct pressure sensor for safe injection and could join work with international brands of GE,Philips and Toshiba</h5>
                <h5 className='md:text-base text-sm max-lg:text-center text-gray-600 mb-4'>Argon Medical Systems offers you Both Nemoto and Medrad Single channel and double channel injector for your CT scan System at good price.</h5>
                <h5 className='md:text-base text-sm max-lg:text-center text-gray-600 mb-6'>The new Injector has been ergonomically designed considering every detail during the products use, matching the product for the objective. This has made possible extremely intuitive and simple operations, significantly enhancing the user confidence during operation.</h5>
                <div className="detail mb-8">
                  <div className='flex flex-col gap-2.5 max-md:text-center max-xsm:text-start bg-gray-50 shadow-sm cursor-pointer p-4 rounded-md'>
                    <div className=''>
                      <h2 className='text-gray-600 font-600 mb-1'>Injection Status LED light</h2>
                    </div>
                    <div className=''>
                      <h2 className='text-gray-600 font-600 mb-1'>Injection status is provided via LED and audible sound</h2>
                    </div>
                    <div className=''>
                      <h2 className='text-gray-600 font-600 mb-1'>Quick Selection Panel</h2>
                    </div>
                    <div className=''>
                      <h2 className='text-gray-600 font-600 mb-1'>Just touch the part to be examined on the panel.The data setup screen will be displayed.</h2>
                    </div>
                    <div className=''>
                      <h2 className='text-gray-600 font-600 mb-1'>One-touch Mounting</h2>
                    </div>
                    <div className=''>
                      <h2 className='text-gray-600 font-600 mb-1'>Just insert and rotate the syringe. Simple, reliable mounting</h2>
                    </div>
                    <div className=''>
                      <h2 className='text-gray-600 font-600 mb-1'>Home Page</h2>
                    </div>
                    <div className=''>
                      <h2 className='text-gray-600 font-600 mb-1'>Simple menu display with easy to follow icons</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Injector
