import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/Button'

const Career = () => {
  return (
    <section className='what-we-do'>
      <div className="md:py-20 py-8 text-center text-white relative bg-[url('../../../src/assets/images/career-bg.jpg')] bg-black/25 bg-blend-darken bg-cover bg-center">
        <h1 className='lg:text-4xl md:text-3xl text-2xl text-white font-500 mb-2.5'>Career</h1>
        <div className="flex gap-2.5 justify-center max-md:text-sm">
          <h4><Link to={"/"}>Home</Link></h4>
          <h4>></h4>
          <h4>Career</h4>
        </div>
      </div>
      <div className='py-10'>
        <div className="container">
          <div className="flex justify-center items-center gap-6 sm:mb-10 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>Join Our Team</h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <h5 className='md:text-base text-sm text-gray-600 text-center max-w-[800px] mx-auto mb-8'>Argon Medical Systems is a great place to work. We are always looking for awesome people to add to our team, send us a message!</h5>
          <div className="form-wrapper max-w-[800px] mx-auto">
            <form className='flex flex-col sm:gap-6 gap-4'>
              <div className="grid sm:grid-cols-2 w-full gap-4">
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="fName" className='mb-1'>First Name</label>
                  <input type="text" id='fName' placeholder='John' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="lName" className='mb-1'>Last Name</label>
                  <input type="text" id='lName' placeholder='Doe' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
              </div>
              <div className="grid sm:grid-cols-2 w-full gap-4">
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="email" className='mb-1'>Email adress</label>
                  <input type="text" id='email' placeholder='abc@gmail.com' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="phone" className='mb-1'>Phone No.</label>
                  <input type="number" id='phone' placeholder='9980998020' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
              </div>
              <div className="grid sm:grid-cols-2 w-full gap-4">
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="desiredPosition" className='mb-1'>Desired Position</label>
                  <input type="text" id='desiredPosition' placeholder='sales' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="resume" className='mb-1'>Upload Resume</label>
                  <input type="file" id='resume' placeholder='9980998020' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="phone" className='mb-1'>Message</label>
                <textarea id="phone" rows="4" className='w-full border-2 outline-none border-gray-400 p-2 px-4'></textarea>
              </div>
              <Button className="w-max px-8">Submit</Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Career
