import React from 'react'
import IconPartAndTube from '../../components/svg/IconPartAndTube'
import PageHeader from '../../components/common/PageHeader'
import IconCorporateOffice from '../../components/svg/IconCorporateOffice'
import IconWarehouse from '../../components/svg/IconWarehouse'
import { Button } from '../../components/ui/Button'
import { Link } from 'react-router-dom'

const ContactUs = () => {
  return (
    <section className='what-we-do'>
      <div className="md:py-20 py-8 text-center text-white relative bg-[url('../../../src/assets/images/contactus-bg.jpg')] bg-black/25 bg-blend-darken bg-cover bg-center">
        <h1 className='lg:text-4xl md:text-3xl text-2xl text-white font-500 mb-2.5'>Contact Us</h1>
        <div className="flex gap-2.5 justify-center max-md:text-sm">
          <h4><Link to={"/"}>Home</Link></h4>
          <h4>></h4>
          <h4>Contact Us</h4>
        </div>
      </div>
      <div className='bg-gray-100'>
        <div className="container">
          <div className="lg:py-12 sm:py-8 py-6">
            <div className="grid lg:grid-cols-3 sm:gap-6 gap-4">
              <div className="card w-full bg-white rounded-lg sm:py-12 px-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="zoom-in" data-aos-duration="2000">
                <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square">
                  <IconCorporateOffice className="lg:w-20 w-16 bg-white p-2 shadow-md" />
                </div>
                <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>CORPORATE OFFICE</h2>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>412-413, Radhika Optima
                  Near Yamuna Chowk, Mota Varachha
                  Surat, Gujarat 394101.
                </h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>Ph. +91 87581 50698</h5>
              </div>
              <div className="card w-full bg-white rounded-lg sm:py-12 px-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="zoom-in" data-aos-duration="2000">
                <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square">
                  <IconWarehouse className="lg:w-20 w-16 bg-white p-2 shadow-md" />
                </div>
                <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>HEAD OFFICE</h2>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>A-17, Scheme 78 Part 1,
                  New Loha Mandi, Dewas Naka,
                  Indore, Madhya Pradesh 453771.
                </h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>Phone: +91 79903 29076</h5>
              </div>
              <div className="card w-full bg-white rounded-lg sm:py-12 px-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="zoom-in" data-aos-duration="2000">
                <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square">
                  <IconPartAndTube className="lg:w-20 w-16 bg-white p-2 shadow-md" />
                </div>
                <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>EMAIL & PHONE NO.</h2>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>argonmedicalsystemsllp@gmail.com</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>Phone no: +91 79907 00830</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='py-10'>
        <div className="container">
          <div className="flex justify-center items-center gap-6 sm:mb-10 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>Get In Touch With Us</h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <div className="form-wrapper max-w-[800px] mx-auto">
            <form className='flex flex-col sm:gap-6 gap-4'>
              <div className="grid sm:grid-cols-2 w-full gap-4">
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="fName" className='mb-1'>First Name</label>
                  <input type="text" id='fName' placeholder='John' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="lName" className='mb-1'>Last Name</label>
                  <input type="text" id='lName' placeholder='Doe' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="subject" className='mb-1'>Subject</label>
                <input type="text" id='subject' placeholder='e.g.' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="email" className='mb-1'>Email adress</label>
                <input type="text" id='email' placeholder='abc@gmail.com' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="phone" className='mb-1'>Phone No.</label>
                <input type="number" id='phone' placeholder='9980998020' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="phone" className='mb-1'>Message</label>
                <textarea id="phone" rows="4" className='w-full border-2 outline-none border-gray-400 p-2 px-4'></textarea>
              </div>
              <Button className="w-max px-8">Submit</Button>
            </form>
          </div>
          <div className="map w-full pt-6">
            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d72498.57018848111!2d75.83749836692256!3d22.784659019229807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39631d5f403619af%3A0xe1569400ef7eb713!2sArgon%20Medical%20Systems%20LLP!5e0!3m2!1sen!2sin!4v1712631731471!5m2!1sen!2sin" width="100%" height="350" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
