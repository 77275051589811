import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import "./assets/css/App.css"
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Product from "./pages/Product/Product";
import Injector from "./pages/Product/Injector";
import Services from "./pages/Services/Services";
import SpareParts from "./pages/SpareParts/SpareParts";
import ContactUs from "./pages/ContactUs/ContactUs";
import { Suspense, useEffect } from "react";
import Career from "./pages/Career/Career";
import AllProducts from "./pages/Products/AllProducts";

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<AllProducts />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/injector" element={<Injector />} />
            <Route path="/services" element={<Services />} />
            <Route path="/spare-parts" element={<SpareParts />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
