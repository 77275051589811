import React from 'react'
import HeroSection from './components/HeroSection'
import Welcome from './components/Welcome'
import WhatWeDo from './components/WhatWeDo'
import OurProduct from './components/OurProduct'
import OurService from './components/OurService'
import WhatOurCustomerSay from './components/WhatOurCustomerSay'

const Home = () => {
  return (
    <>
      <HeroSection />
      <Welcome />
      <WhatWeDo />
      <OurProduct />
      <OurService />
      <WhatOurCustomerSay />
    </>
  )
}

export default Home
