import img1 from "./assets/images/product1.jpg"
import img2 from "./assets/images/product2.jpg"
import img3 from "./assets/images/product3.jpg"
import img4 from "./assets/images/product4.jpg"
import img5 from "./assets/images/product5.jpg"
import img6 from "./assets/images/product6.jpg"
import img7 from "./assets/images/product7.jpg"
import img8 from "./assets/images/product8.jpg"

export const productData = [
  {
    id: 1,
    title: 'Aquilion PRIME 160',
    img: img8,
    description: [
      "Aquilion PRIME incorporates cutting-edge technologies designed to meet your current and future clinical needs. Innovative features ensure that high-quality images for best possible diagnosis are routinely acquired with lowest possible patient dose. The workflow is streamlined increasing your patient throughput and reducing waiting times.",
      "Minimization of the radiation dose is a high priority for all medical imaging practitioners. The dose has to be adjusted appropriately according to the size and shape of each patient. Automatic exposure control systems have proven to be useful in doing this while maintaining diagnostic image quality. Toshiba’s SUREExposure 3D adaptive exposure control system is fully integrated into the imaging chain and can therefore calculate the minimum radiation exposure required for each examination of each patient. With the inclusion of Adaptive Iterative Dose Reduction 3D (AIDR 3D) in the scan protocol, the exposure dose is automatically reduced by up to 75% relative to that in a scan performed with traditional filtered back-projection reconstruction.",
      "For a dose reduction technology to be truly useful in clinical practice, it must be fast enough to fit into busy workflow schedules. AIDR 3D reconstruction has therefore been systematically optimized to guarantee maximum on patient throughput. As a result, advanced iterative reconstruction with AIDR 3D can be used for every patient and every scan.",
    ],
    features: [
      "Maximum number of slices 160 slices/rotation",
      "0.35 s Fastest rotation time  ",
      "72 kW Maximum generator power ",
      "7.5 MHU X-ray tube unit heat capacity ",
      "78 cm Gantry bore ",
      "Reconstruction speed Up to 60 fps* with AIDR 3D",
      "Low contrast resolution 2 mm @ 0.3%",
      "Fast Reconstruction",
      "80-detector row helical",
      "Metal less Head rest",
      "Metal less Arm up holder",
      "SUREXtension",
      "Ultra Low Dose Examination",
      "AIDR",
      "Active Collimator",
      "SUREExposure 3D",
      "Boost3D",
      "Dose Index Value Display",
    ]
  },
  {
    id: 2,
    title: 'Aquilion Lightning 16',
    img: img7,
    description: [
      "Aquilion Lightning employs cutting-edge technologies to optimize patient care and accelerate clinical decision making. Innovative features ensure that high-quality isotropic images for best possible diagnosis are routinely acquired with the lowest possible patient dose. The workflow is streamlined, increasing patient throughput. And a wide range of advanced 3D and post processing applications provide clinical flexibility. Together, these features make Aquilion Lightning a powerful workhorse.",
      "The Aquilion Lightning gantry features design innovations to improve the scanning experience for patients as well as providing excellent operability and ensuring safety. The spacious 780 mm wide bore and 470 mm wide couch ensure comfortable scanning for even the largest patients. With a design also focusing on smaller installation space and power consumption, Aquilion Lightning requires a minimal footprint of 9.8 m2*, compact enough to meet even the most restrictive siting requirements. Innovative Adaptive Power Management technologies dramatically decrease energy requirements, reducing running costs and easing the environmental impact.",
      "Adaptive Iterative Dose Reduction (AIDR) 3D Enhanced technology reduces the dose to the lowest level consistent with quality imaging for every patient, every time. Automated selection of tube voltage with SUREkV. Both technologies are standard on the Aquilion Lightning system.",
    ],
    features: [
      "0.5 mm x 16 row, 32-slice option",
      "78 cm bore",
      "AIDR 3D enhanced",
      "660 lb. large-capacity couch",
      "SEMAR",
      "PUREViSION Detector Technology",
      "XR 29 compliant Technology",
      "XR 29 compliant",
      "0.75s(0.6 s*) Rotation time",
      "42kW High Power generator",
      "Gantry tilt angle ±30°",
      "Real-time helical reconstruction time is 12 images/s (0.083 s/image) (1 slice, 512 × 512 matrix)",
      "Image reconstruction time is Up to 15 images/s (0.066 s/image)",
      "Integrated and easy to use contrast and radiation dose management",
      "Automated kV selection based on patient size, SUREExposure settings and clinical task",
      "Clinically targeted kV selections of: 80, 100, 120, 135",
    ]
  },
  {
    id: 3,
    title: 'Aquilion 64',
    img: img6,
    description: [
      "The World's Most Advanced 64-Slice CT System– The Aquilion 64 allows physicians to easily capture precise, motion-free images of the heart and coronary arteries to identify soft plaque or measure coronary blockage, and detect signs of disease at its earliest stages, reducing the need for high-risk surgical procedures. Featuring the most advanced multi-detector CT technology available, the Aquilion 64-slice CT is built on the same platform as the Aquilion 32, utilizing Toshiba's unique 64-row Quantum detector, which enables the Aquilion CT scanner to acquire 64 simultaneous slices of 0.5 mm with each 400-millisecond gantry revolution, resulting in precise isotropic imaging of any region of the body. Additionally, the Aquilion 64 superior multi-detector design produces the best low contrast resolution at the lowest dose.",
      "With Toshiba's patented PianissimoT technology, which effectively reduces acoustic noise by as much as 90 percent, the Vantage significantly reduces the most significant cause for patient discomfort and enables clinicians to better utilize the system's high-field MRI capabilities and SPEEDER parallel processing for increased imaging acquisition speed and reduced exam times.",
      "High performance in image quality and speed must go hand in hand with efficient workflow. The Aquilion 64 has specifically addressed workflow integration to optimize productivity and enhance diagnosis.",
    ],
    features: [
      "SafeCT Low-dose Solution",
      "Ergonomic design for patient comfort",
      "7.5 MHU Tube",
      "Contrast detection at 12 Frames Per Second",
      "Low contrast resolution",
      "60kW High Power Generator",
      "Superior Spatial Resolution at 0.35mm for Small Vessel Detail",
      "Real-Time Helical Surestart is Included",
      "High Quality Image",
      "0.5 Second Gantry Rotation",
      "Flexible choice of speed and accuracy",
      "Fast Color 3D package",
      "Volume Image Processor",
      "True Volumetric Imaging",
      "Reconstruction Time per Slice 0.1 Second",
    ]
  },
  {
    id: 4,
    title: 'Aquilion 16',
    img: img5,
    subTitle: 'The Power to Perform,Uncompromised Technology',
    description: "This advanced CT system includes enhanced features for greater patient comfort and safety, faster exams, and improved image quality for quicker diagnosis. In 400 milliseconds, the gantry can rotate around a patient's entire body—a fast scanning capability that can effectively reduce image distortion of moving organs, such as the heart and lungs. Delivering more accurate clinical images with fine details, the Aquilion 16 offers physicians the high quality images they need to make a quick and accurate diagnosis.",
    gantryAperture: "720",
    scanTime: "0.32s(partial) ,0.50,0.75,1 , 1.5 ,2 and 3s",
    color3dImage: "processing/MPR",
    gantryTiltAngle: "±30 degree(in 0.5° increment)",
    voltage: "80 ,100 ,120 ,135",
    imageReconTime: "Fast up 10 images per second",
    heatingCapacity: "7.5",
  },
  {
    id: 5,
    title: 'Alexion 16',
    img: img4,
    description: "Alexion / Advance Edition is a CT system featuring Toshiba's exclusive, industry leading Quantum Detector technology, the 16 row Alexion / Advance Edition in-corporates 0.5 mm detector technology for acquiring true isotropic voxels. The 0.5 mm detector elements (the smallest in current CT technology) provide razor-sharp images, ensuring fast and accurate diagnosis in all parts of the body with a lower exposure dose.",
    gantryAperture: "720",
    scanTime: "0.75,1 , 1.5 ,2 and 3s",
    capacity: "2.0",
    gantryTiltAngle: "±30 degree(in 0.5° increment)",
    voltage: "80 ,100 ,120 ,135",
    imageReconTime: "Min. 0.25s time",
    aidr3D: true,
    sureExposure3D: true
  },
  {
    id: 6,
    title: 'Activion 16',
    img: img3,
    subTitle: '"Ready-Set-Go" redefines "Easy Operation"',
    description: "The Activion-16 offers fast advanced acquisitions through easy operation by just three steps: Ready (select the scan region) - Set (perform scan planning) -Go (start scanning). The user is guided through each step of the scan planning even further simplifying the process. The system's design maximizes both operator and patient comfort, allowing the operator to focus on the examination and the patient rather than on the system. Ideal when procedures are not often done. Even experienced staff will find Activion-16 the user-friendliest system.",
    gantryAperture: "720",
    scanTime: "0.48s(partial),0.75 ,1 , 1.5 ,2 and 3s",
    color3dImage: "processing/MPR",
    gantryTiltAngle: "±30 degree(in 0.5° increment)",
    voltage: "80 ,100 ,120 ,135",
    imageReconTime: "Min. 0.17s time",
    heatingCapacity: "4.0",
  },
  {
    id: 7,
    title: 'Asteion Super-4',
    img: img2,
    description: "The Asteion super 4 is a multislice Helical CT scanner that supports whole body scanning.The system generates 5.3 slices per second using a Selectable Slice thickness Multi row Detector (SSMD).Since it can also perform high speed re-construction at amaximum 4 images per second, every examination can be processed at ultra-high speed. In combination with the continuous Imaging technology, it is possible to quickly perform more accurate scanning and examinations.",
    gantryAperture: "720",
    scanTime: "0.75,1 , 1.5 ,2 and 3s",
    capacity: "4.0",
    color3dImage: "processing/MPR",
    gantryTiltAngle: "±30 degree(in 0.5° increment)",
    voltage: "80 ,100 ,120 ,135",
    imageReconTime: "Min. 0.25s time",
  },
  {
    id: 8,
    title: 'Asteion Single Slice VP',
    img: img1,
    description: "Asteion VP is a whole body computed tomography (CT) scanner that combines advanced acquisition with simple operation. A unique and intuitive operation mode, Guided Mode, is available. The combination of high end CT technology with ease of operation and various applications for dose reduction ensures exceptional image quality at low dose in all examinations. A high performance CPU, 19-inch LCD color monitor, hybrid keyboard, and advanced GUI are employed to ensure an efficient operating environment.",
    gantryAperture: "720",
    scanTime: "0.6,1 , 1.5 ,2 and 3s",
    capacity: "2.0",
    color3dImage: "processing/MPR",
    gantryTiltAngle: "±30 degree(in 0.5° increment)",
    voltage: "80 ,100 ,120 ,135",
    imageReconTime: "Min. 1.00s time",
    detectionSystem: "Solid-state detectors",
  },
]

export const serviceData = [
  {
    tabName: "Preventive",
    title: "Preventive Maintenance Contract",
    img: "preventive.jpg",
    description: [
      "Argon provides Preventive maintenance agreement to its customer. The package cost is included for service charges only for a desired period. The cost of spares replaced is payable by Customer."
    ],
    benifit: {
      title: "Preventive maintenance services at fixed expense, including",
      benifits: [
        "Checking and recalibration of machine in a year",
        "Attending all breakdown at no extra cost",
        "Two preventive maintenance inspections during the twelve month agreement period.",
        "No charges for travel and visit charge",
        "Reduced radiation dose to patient and operator",
        "Minimum Repair Time"
      ]
    }
  },
  {
    tabName: "Comprehensive",
    title: "Comprehensive Maintenance Contract",
    img: "comprehensive.jpg",
    description: [
      "With Argon Medical Systems Comprehensive Maintenance Agreement you’ll receive the highest quality service possible, With 10 years of the experience from the oldest to the newest CT scanner our engineers are factory-trained and certified.",
      "Argon provides all travel, labor and parts for your system, as well as three preventative maintenance inspections during a twelve month period. Our goal is to respond to all service calls within eight working hours. In addition, our technical support department provides phone support at no additional charge."
    ],
  },
  {
    tabName: "Equipment Relocation",
    title: "Equipment Relocation & Installation",
    img: "relocation.jpg",
    description: [
      "We can move your Precious CT Scanner system in less time and with less cost. With our experienced and trained installation team, and having the right tools and equipment, we know the intricacies of a safe and fast relocation of your assets for more productive use. Our installations and relocations are done entirely by Argon Medical Systems highly experienced service engineer’s team led by a Project Manager and supported by Modality Operations, Service Managers. This support ensures visibility to your needs as a customer from all areas of Argon Medical Systems."
    ],
  },
]