import React from 'react'

const Welcome = () => {
  return (
    <section className='welcome-section py-10 bg-theme'>
      <div className="container">
        <div className="grid lg:grid-cols-2 items-center justify-center">
          <div className="content max-lg:text-center text-white" data-aos="fade-right" data-aos-duration="1500">
            <h2 className='md:text-2xl text-lg font-600 sm:mb-8 mb-6' >Welcome to <span className='md:text-3xl sm:text-2xl text-xl text-[#ffdd9a]'>Argon Medical Systems LLP</span></h2>
            <h5 className='md:text-base sm:text-sm text-xs sm:font-400 mb-4'>Argon is an organization specializing in offering affordable medical imaging equipment and providing impeccable after sales & maintenance service to our valuable customer. It is involved in Medical Imaging Solutions Such As Toshiba CT Scanner, Service and it's parts.</h5>
            <h5 className='md:text-base sm:text-sm text-xs sm:font-400'>Our Comprehensive range of product, competitive price, cost policy and timely delivery has given us a winning edge in the market. Dealing with refurbished Toshiba CT scanners from Japan. Argon is widely acclaimed by the medical fraternity in India for its outstanding service with the highest uptime and maintaining excellent customer relationship.</h5>
          </div>
          <div className="max-w-[80%] mx-auto" data-aos="fade-left" data-aos-duration="1500">
            <img src={require("../../../assets/images/welcome.png")} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Welcome
