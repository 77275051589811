import React, { useEffect } from 'react'
import Header from '../common/Header'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../common/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 1000)
  }, [])
  return (
    <>
      <Header />
      <main className='xl:mt-[133px] lg:mt-[117px] sm:mt-[73px] mt-[62px]'>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default Layout
